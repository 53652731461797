import { Injectable, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class NoticeService {
  private snackBar: MatSnackBar = inject(MatSnackBar);
  private dialog: MatDialog = inject(MatDialog)

  showSnackbar(
    message: string,
    action: string = 'Cerrar',
    config?: MatSnackBarConfig
  ): void {
    const defaultConfig: MatSnackBarConfig = {
      duration: 4000,
      ...config,
    };

    this.snackBar.open(message, action, defaultConfig);
  }
}
